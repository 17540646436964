<template>
  <Chatbox msg="Welcome to Your Vue.js App"/>
</template>

<script>
import Chatbox from './components/Chatbox.vue'

export default {
  name: 'App',
  components: {
    Chatbox
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

#app {
  font-family: "Montserrat", Sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #173557;
}
body{
  margin:0px;
  background-color: #173557;
}
</style>
